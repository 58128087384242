/* eslint-disable max-len */
/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../styles/commonStyles.module.css";
import Image from "next/image";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const faqs = [
  {
    collapse: true,
    title: msg`How do I sign up for a free trial of ZenClass?`,
    desc: msg`To sign up for a free trial of ZenClass, simply visit our website and click on the "Claim your 30-day Free Trial Now" button. Follow the prompts to create your account and start enjoying the benefits of ZenClass.`,
  },
  {
    collapse: false,

    title: msg`Can I import my existing lesson plans into ZenClass?`,
    desc: msg`Yes, you can import your existing lesson plans into ZenClass. Our platform supports various file formats, making it easy to migrate your content and continue using your preferred teaching materials.`,
  },
  {
    collapse: false,

    title: msg`Is ZenClass suitable for remote teaching?`,
    desc: msg`Absolutely! ZenClass is designed to support both in-person and remote teaching. Our intuitive interface and collaborative features enable seamless communication and engagement with your students, regardless of their physical location.`,
  },
  {
    collapse: false,
    title: msg`Can I track individual student progress and attendance?`,
    desc: msg`Yes, with ZenClass, you can efficiently track student progress and attendance. Our Student Tracker provides a user-friendly interface to record and monitor each student's growth, enabling you to provide personalized support and ensure better outcomes.`,
  },
  {
    collapse: false,
    title: msg`Is ZenClass compatible with other educational tools?`,
    desc: msg`ZenClass integrates with a wide range of educational tools and platforms, allowing you to leverage your existing resources. Whether you use learning management systems, productivity apps, or assessment tools, ZenClass enhances your teaching experience by providing a centralized hub for all your needs.`,
  },
];

interface Props {
  faq: any;
}

export const Description = ({ faq }: Props) => {
  const refHeight = useRef<any>();
  const { i18n } = useLingui();

  const getHeight = () => {
    if (refHeight.current) {
      return refHeight.current.clientHeight;
    }
    return "auto";
  };

  const renderListDesc = (desc: any[]) => {
    return desc.map((d, index) => <div key={index}>{i18n._(d)}</div>);
  };

  return (
    <>
      <div
        ref={refHeight}
        style={{ opacity: 0, pointerEvents: "none", position: "absolute" }}
        className={`${styles.questionItemDesc} ${styles.questionItemDescOpacity}`}
      >
        {faq.multi ? renderListDesc(faq.desc) : i18n._(faq.desc)}
      </div>
      {
        <div
          style={{
            height: faq.collapse ? getHeight() : "0px",
            marginTop: faq.collapse ? "10px" : 0,
            opacity: faq.collapse ? 0.7 : 0,
          }}
          className={styles.questionItemDesc}
        >
          {faq.multi ? renderListDesc(faq.desc) : i18n._(faq.desc)}
        </div>
      }
    </>
  );
};

const Faqs: React.FC = () => {
  const [faqsState, setFaqs] = useState(faqs);
  const { i18n } = useLingui();

  const toggleCollapse = (i: number) => {
    const cFaqsState = [...faqsState];
    cFaqsState[i].collapse = !cFaqsState[i].collapse;
    setFaqs(cFaqsState);
  };
  return (
    <div className={styles.faqsContainer}>
      <div className={commonStyles.container}>
        <div>
          <div className={styles.questionsContainer}>
            <div className={styles.title}>
              <Trans>Frequently Asked Questions</Trans>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 12,
                position: "relative",
              }}
            >
              <div className={styles.circleSm} />
              {faqsState.map((faq, index) => (
                <div
                  key={index}
                  className={styles.questionItem}
                  onClick={() => toggleCollapse(index)}
                  style={{
                    backgroundColor: faq.collapse
                      ? "var(--background-background-2, rgba(255, 248, 225, 0.50))"
                      : "var(--background-background-1, #FCFAF4)",
                  }}
                >
                  <div className={styles.questionItemTitle}>
                    <div>{i18n._(faq.title)}</div>
                    <div
                      style={{ minWidth: 26, height: 26, position: "relative" }}
                    >
                      <Image
                        layout="fill"
                        alt="icon"
                        src={`/v3/${
                          faq.collapse ? "collapse" : "collapsed"
                        }.svg`}
                      />
                    </div>
                  </div>
                  <Description faq={faq} />
                </div>
              ))}
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className={styles.circle} />
            <div className={styles.toolContainer}>
              <div className={styles.toolContent}>
                <Image alt="logo" src="/v3/logo.svg" width={182} height={48} />
                <section>
                  <div>
                    <Trans>We’re Connected with The Tools</Trans>
                    <br />
                    <Trans>You’re Already Using</Trans>
                  </div>
                  <div>
                    <Trans>
                      Work with the other tools and platforms you’re already
                      using.
                    </Trans>
                  </div>
                  <div>
                    <Trans>
                      Streamline the learning experience for you and your
                      students, because everything’s in one place and accessible
                      through a single link.
                    </Trans>
                  </div>
                </section>
                <a
                  target="_blank"
                  href="https://app.zenklass.com/signin"
                  rel="noreferrer"
                >
                  <div className={commonStyles.buttonPrimary}>
                    <span>
                      <Trans>Explore now</Trans>
                    </span>
                    <Image
                      src="/v3/thumb_up.svg"
                      width={20}
                      height={20}
                      alt="icon"
                    />
                  </div>
                </a>
              </div>
              <div className={styles.toolIcon}>
                <div className={styles.toolList}>
                  {[1, 2, 3, 4, 5, 6].map((icon, index) => (
                    <div key={index} className={styles.icon}>
                      <Image
                        src={`/v3/tools/${icon}.svg`}
                        layout="fill"
                        alt="icon"
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.toolLogo}>
                  <section style={{ position: 'relative', height: '100%' }}>
                    <Image
                      layout="fill"
                      src="/v3/tools/logo.png"
                      alt="icon"
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
