// import Footer from "components/Footer";
import Header from "components/Header";
import Notifaction from "components/Notification";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import styles from '../../styles/commonStyles.module.css';
import { GetStaticPropsContext } from "next";

interface Props {
  children: React.ReactNode,
  activeNoti?: boolean,
  activeHeader?: boolean,
  locale?: string
}

const Toaster = dynamic(() => import("components/Toaster"), {
  ssr: false,
});

const FooterNoSSR = dynamic(() => import('components/Footer'), {
  ssr: false
});

export const Layout: React.FC<Props> = ({
  children,
  activeNoti,
  activeHeader,
  locale
}) => {
  const [active, setActive] = useState<any>(activeNoti);

  const closeNotification = () => {
    setActive(false);
  };

  useEffect(() => {
    setActive(activeNoti);
  }, [activeNoti]);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div className={activeHeader ? styles.main : ''}>
        <main>
          <Header active={activeHeader} locale={locale} />
          {children}
          <FooterNoSSR />
          <Notifaction active={active} handleClose={closeNotification} />
        </main>
        <Toaster />
      </div>
    </div>
  );
};

export default Layout;
