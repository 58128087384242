import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../styles/commonStyles.module.css";
import Image from "next/image";
import { Trans, msg } from "@lingui/macro";
import Link from "next/link";
import { useRouter } from "next/router";
import { GetStaticPropsContext } from "next";
import { i18n } from "@lingui/core";

const dropdown = [
  {
    title: msg`Schedule a Demo`,
    desc: msg`Let's find a time to chat`,
    url: "https://y5pi357qz8g.larksuite.com/scheduler/bb01d96fc701c455",
  },
  // {
  //   title: 'Blog',
  //   desc: 'Unlock insights and stay informed',
  //   url: 'https://block.zenklass.com'
  // },
  {
    title: msg`Contact Us`,
    desc: msg`Connect with our dedicated team`,
    url: "/contact",
  },
  // {
  //   title: 'Case Studies',
  //   desc: 'Discover success stories and real world impact',
  //   url: 'https://block.zenklass.com'
  // },
  {
    title: msg`FAQs`,
    desc: msg`List of most asked questions`,
    url: "https://help.zenclass.co/faqs",
  },
  {
    title: msg`Guides`,
    desc: msg`Step by step instructions for creating custom workflows and more`,
    url: "https://help.zenclass.co",
  },
];

interface Props {
  active?: boolean;
  locale?: string;
}

const locales = [
  { name: "English", code: "en" },
  { name: "Vietnam", code: "vn" },
];

const Header: React.FC<Props> = ({ active = true, locale }) => {
  const router = useRouter();
  const [openMenu, setOpenMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openTranslate, setOpenTranslate] = useState(false);
  const [key, setKey] = useState(0);
  const lang = locale;

  const getActiveMenu = (path: string) => {
    if (router.pathname === path) return styles.menuItemActive;
  };

  useEffect(() => {
    setKey(key + 1);
  }, [locale]);

  return (
    <div
      key={key}
      className={styles.header}
      style={{ top: active ? "0" : "-90px" }}
    >
      <div className={commonStyles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.logo}>
            <Link href="/">
              <a>
                <Image src="/v3/logo.svg" layout="fill" alt="logo" />
              </a>
            </Link>
          </div>
          <div className={styles.listMenu}>
            <Link href="/">
              <a>
                <div className={`${styles.menuItem} ${getActiveMenu("/")}`}>
                  <Trans>Home</Trans>
                </div>
              </a>
            </Link>
            {/* <div className={styles.menuItem}><Trans>Features</Trans></div> */}
            {/* <Link href="/pricing">
              <a>
                <div className={`${styles.menuItem} ${getActiveMenu('/pricing')}`}><Trans>Pricing</Trans></div>
              </a>
            </Link> */}
            <div
              className={`${styles.menuItem} ${styles.dropdown} ${getActiveMenu(
                "/contact"
              )}`}
            >
              <span>
                <Trans>Resources</Trans>
              </span>
              <Image
                className={styles.arrow}
                alt="arrow"
                src="/v3/arrow-down.svg"
                width={24}
                height={24}
              />
              <div className={styles.menuItemDropdown}>
                {dropdown.map((s, index) => (
                  <Link href={s.url || "/404"} key={index}>
                    <a>
                      <section>
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            position: "relative",
                          }}
                        >
                          <Image
                            src={`/v3/menu/${index + 1}.svg`}
                            alt="menu"
                            layout="fill"
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>{i18n._(s.title)}</span>
                          <br />
                          <span>{i18n._(s.desc)}</span>
                        </div>
                      </section>
                    </a>
                  </Link>
                ))}
              </div>
            </div>
            <div className={`${styles.menuItem} ${styles.dropdown}`}>
              <div
                style={{
                  marginRight: 5,
                  width: 26,
                  height: 26,
                  position: "relative",
                  border: "2px solid #0D715F",
                  borderRadius: "100%",
                }}
              >
                <Image
                  src={`/v3/lingui/${lang}.png`}
                  alt="menu"
                  layout="fill"
                />
              </div>
              <span>{lang === "vn" ? "Vietnam" : "English"}</span>
              <Image
                className={styles.arrow}
                alt="arrow"
                src="/v3/arrow-down.svg"
                width={24}
                height={24}
              />
              <div
                className={`${styles.menuItemDropdown} ${styles.dropdownTranslate}`}
              >
                {locales.map((l) => (
                  <Link
                    href={router.asPath}
                    locale={l.code}
                    key={l.code}
                    scroll={false}
                  >
                    <section>
                      <div
                        style={{
                          width: 26,
                          height: 26,
                          position: "relative",
                          borderColor:
                            lang === l.code ? "#0D715F" : "transparent",
                          borderWidth: lang === l.code ? "2px" : "0px",
                        }}
                      >
                        <Image
                          src={`/v3/lingui/${l.code}.png`}
                          alt="menu"
                          layout="fill"
                        />
                      </div>
                      <div>{l.name}</div>
                    </section>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <a
              href="https://app.zenklass.com/signin"
              target="_blank"
              rel="noreferrer"
            >
              <div className={commonStyles.buttonNoBorder}>
                <Trans>Login</Trans>
              </div>
            </a>
            <a
              href="https://app.zenklass.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              <div className={commonStyles.buttonPrimary}>
                <Trans>Claim a Free Trial</Trans>
              </div>
            </a>
          </div>
          <div className={styles.menu}>
            <Image
              onClick={() => setOpenMenu(!openMenu)}
              alt="menu"
              width={24}
              height={24}
              src={`/v3/${openMenu ? "close-menu" : "menu"}.svg`}
            />
          </div>
        </div>
      </div>
      {openMenu && (
        <div className={styles.sideMenu}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px 0",
            }}
          >
            <Link href="/">
              <a>
                <div className={`${styles.menuItem} ${getActiveMenu("/")}`}>
                  <Trans>Home</Trans>
                </div>
              </a>
            </Link>
            {/* <div className={styles.menuItem}><Trans>Features</Trans></div> */}
            {/* <Link href="/pricing">
                <a>
                  <div className={`${styles.menuItem} ${getActiveMenu('/pricing')}`}><Trans>Pricing</Trans></div>
                </a>
              </Link> */}
            <div
              className={`${styles.menuItem} ${
                openDropdown ? styles.menuItemActive : ""
              }`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <span>
                <Trans>Resources</Trans>
              </span>
              <Image
                className={styles.arrow}
                alt="arrow"
                src="/v3/arrow-down.svg"
                width={24}
                height={24}
              />
            </div>
            <div
              className={styles.menuItemDropdownMobile}
              style={{ height: openDropdown ? 292 : 0 }}
            >
              {dropdown.map((s, index) => (
                <Link
                  href={s.url || "/404"}
                  key={index}
                  locale={locale}
                >
                  <a target={s.url?.startsWith("/") ? "_self" : "_blank"}>
                    <section key={index}>
                      <div
                        style={{ width: 24, height: 24, position: "relative" }}
                      >
                        <Image
                          src={`/v3/menu/${index + 1}.svg`}
                          alt="menu"
                          layout="fill"
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div>{i18n._(s.title)}</div>
                        <div>{i18n._(s.desc)}</div>
                      </div>
                    </section>
                  </a>
                </Link>
              ))}
            </div>
            <div
              className={`${styles.menuItem} ${
                openTranslate ? styles.menuItemActive : ""
              }`}
              onClick={() => setOpenTranslate(!openTranslate)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    marginRight: 5,
                    width: 26,
                    height: 26,
                    position: "relative",
                    border: "2px solid #0D715F",
                    borderRadius: "100%",
                  }}
                >
                  <Image
                    src={`/v3/lingui/${lang}.png`}
                    alt="menu"
                    layout="fill"
                  />
                </div>
                <span>{lang === "vn" ? "Vietnam" : "English"}</span>
              </div>
              <Image
                className={styles.arrow}
                alt="arrow"
                src="/v3/arrow-down.svg"
                width={24}
                height={24}
              />
            </div>
            <div
              className={styles.menuItemDropdownMobile}
              style={{ height: openTranslate ? 100 : 0 }}
            >
              {locales.map((l) => (
                <Link
                  href={router.asPath}
                  locale={l.code}
                  key={l.code}
                  scroll={false}
                >
                  <section
                    onClick={() => {
                      setOpenTranslate(false);
                    }}
                  >
                    <div
                      style={{
                        width: 26,
                        height: 26,
                        position: "relative",
                        borderColor:
                          lang === l.code ? "#0D715F" : "transparent",
                        borderWidth: lang === l.code ? "2px" : "0px",
                      }}
                    >
                      <Image
                        src={`/v3/lingui/${l.code}.png`}
                        alt="menu"
                        layout="fill"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: 16,
                        color: "#1D2939",
                        fontWeight: 500,
                      }}
                    >
                      {l.name}
                    </div>
                  </section>
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.buttonLogin}>
            <a
              href="https://app.zenclass.co/signup"
              target="_blank"
              rel="noreferrer"
            >
              <div className={commonStyles.buttonPrimary}>
                <Trans>Sign up</Trans>
              </div>
            </a>
            <a
              href="https://app.zenclass.co/signin"
              target="_blank"
              rel="noreferrer"
            >
              <div className={commonStyles.buttonNoBorder}>
                <Trans>Login</Trans>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
