import React from "react";
import styles from "./styles.module.css";
import Image from "next/image";
import { Trans } from "@lingui/macro";

interface Props {
  active: any;
  handleClose: () => void;
}

const Notifaction: React.FC<Props> = ({ active, handleClose }) => {
  return (
    <div className={`${styles.fixed} ${active ? styles.active : ''}`}>
      <div className={styles.notificationContainer}>
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          <Image src="/v3/slogo.svg" alt="logo" width={56} height={56} />
          <section>
            <div><Trans>Claim your 30-days free trial now!</Trans></div>
            <div><Trans>No credit card require</Trans></div>
          </section>
        </div>
        <div onClick={handleClose} className={styles.button}><Trans>Do not show it again</Trans></div>
        <div className={styles.closeButton}>
          <Image onClick={handleClose} src="/v3/close.svg" alt="close" width={20} height={20} />
        </div>
      </div>
    </div>
  );
};

export default Notifaction;
